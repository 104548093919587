import React from 'react';

import Icon from '@atlaskit/icon';
import VideoIcon from '@atlaskit/icon/core/video';
import type { NewCoreIconProps } from '@atlaskit/icon/UNSAFE_base-new';
import { token } from '@atlaskit/tokens';

type VideoIconProps = Omit<NewCoreIconProps, 'dangerouslySetGlyph' | 'type'>;

const videoSVG = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.5 6.375C4.15482 6.375 3.875 6.65482 3.875 7V17C3.875 17.3452 4.15482 17.625 4.5 17.625H14.5C14.8452 17.625 15.125 17.3452 15.125 17V7C15.125 6.65482 14.8452 6.375 14.5 6.375H4.5ZM2 7C2 5.61929 3.11929 4.5 4.5 4.5H14.5C15.8807 4.5 17 5.61929 17 7V7.98309L19.7387 6.61373C20.7776 6.09427 22 6.84974 22 8.01127V15.9887C22 17.1503 20.7776 17.9057 19.7387 17.3863L17 16.0169V17C17 18.3807 15.8807 19.5 14.5 19.5H4.5C3.11929 19.5 2 18.3807 2 17V7ZM17 13.9206L20.125 15.4831V8.51691L17 10.0794V13.9206Z"
			fill="currentColor"
		/>
	</svg>
);

const CustomVideoIcon = (props: VideoIconProps) => {
	const color = props.color || token('color.text');
	const label = props.label || '';
	return <Icon glyph={videoSVG} {...props} primaryColor={color} label={label} />;
};

export const LoomVideoIcon = (props: VideoIconProps) => {
	const color = props.color || token('color.text');
	const label = props.label || '';
	return (
		<VideoIcon
			LEGACY_fallbackIcon={CustomVideoIcon}
			{...props}
			color={color}
			label={label}
			spacing="spacious"
		/>
	);
};
