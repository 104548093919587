import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import type { FlagsStateContainer } from '@confluence/flags';

const i18n = defineMessages({
	errorTitleCannotStart: {
		id: 'loom-utils.error-flag.title.cannot-start',
		defaultMessage: 'We couldn’t start your recording',
		description:
			'title for a notification flag when a user encounters an error that prevents SDK from starting',
	},
	errorSDKFailedToInitialize: {
		id: 'loom-utils.error-flag.description.sdk-failed-to-initialize',
		defaultMessage: 'Give it a moment and then refresh to try again.',
		description: 'description for an error flag when Loom SDK fails to initialize',
	},
});

type UseLoomEntryPointErrorCallbackProps = {
	flags: FlagsStateContainer;
};

export const useLoomEntryPointErrorCallback = ({ flags }: UseLoomEntryPointErrorCallbackProps) => {
	const intl = useIntl();
	return useCallback(() => {
		void flags.showInfoFlag({
			title: intl.formatMessage(i18n.errorTitleCannotStart),
			description: intl.formatMessage(i18n.errorSDKFailedToInitialize),
		});
	}, [flags, intl]);
};
