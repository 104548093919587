import gql from 'graphql-tag';

export const PageHeaderLoomButtonQuery = gql`
	query PageHeaderLoomButtonQuery($contentId: ID!) {
		contentContributors(id: $contentId) {
			isCurrentUserContributor
		}
		content(id: $contentId) {
			nodes {
				id
				operations {
					operation
					targetType
				}
			}
		}
	}
`;
