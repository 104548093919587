import { useCallback, useEffect } from 'react';

import { useCoordinationClient } from '@confluence/engagement-provider';
import { fg } from '@confluence/feature-gating';

import {
	endPulses,
	havePulsesEnded,
	useDiscoverabilityPulseActions as useBaseDiscoverabilityPulseActions,
	useIsPulseActive,
} from './useDiscoverabilityPulseState';

export const useDiscoverabilityPulseActions = () => {
	const coordinationClient = useCoordinationClient();
	const { stop } = useBaseDiscoverabilityPulseActions();

	const stopPulse = useCallback(() => {
		if (!fg('confluence_frontend_loom_discoverability_pulse')) {
			return;
		}
		void stop(coordinationClient);
	}, [coordinationClient, stop]);

	return { stopPulse };
};

export const useDiscoverabilityPulse = (entryPointId: string) => {
	const coordinationClient = useCoordinationClient();

	const { start: startPulse } = useBaseDiscoverabilityPulseActions();
	const isPulseActive = useIsPulseActive(entryPointId);

	const skipRefreshPulseStatus = isPulseActive || havePulsesEnded();

	useEffect(() => {
		if (skipRefreshPulseStatus || !fg('confluence_frontend_loom_discoverability_pulse')) {
			return;
		}

		const showPulseIfEligible = async (messageId: string) => {
			const isMessageActive = Boolean(await coordinationClient.start(messageId));
			if (isMessageActive) {
				void startPulse(entryPointId, messageId);
			}
			return isMessageActive;
		};

		const refreshPulseStatus = async () => {
			if (await showPulseIfEligible('cc-loom-x-sell-discoverability-pulse-1')) {
				return;
			}

			if (await showPulseIfEligible('cc-loom-x-sell-discoverability-pulse-2')) {
				return;
			}

			if (await showPulseIfEligible('cc-loom-x-sell-discoverability-pulse-3')) {
				return;
			}

			if (await showPulseIfEligible('cc-loom-x-sell-discoverability-pulse-4')) {
				return;
			}

			if (await showPulseIfEligible('cc-loom-x-sell-discoverability-pulse-5')) {
				/**
				 * Mark the EP message as "complete" and disable Loom discoverability in localStorage
				 * immediately after showing the final pulse. Otherwise, if a user ignores the entrypoints
				 * entirely and never clicks on one while it's pulsing, they'll:
				 *
				 * - Remain in the TAP audience forever
				 * - Continue making 5 API requests to check the EP messages every time an entrypoint renders
				 *
				 * This will technically unlock EP to show other messages, but the pulse is subtle enough that
				 * this shouldn't result in poor UX
				 */
				endPulses();
				void coordinationClient.stop('cc-loom-x-sell-discoverability-pulse-5');
			}
		};

		void refreshPulseStatus();
	}, [coordinationClient, entryPointId, skipRefreshPulseStatus, startPulse]);

	/**
	 * Do not include a check of `havePulsesEnded()` here in order to continue showing the pulse
	 * immediately after viewing it a 5th time. Otherwise, the next time the button re-renders
	 * (for any reason), the pulse would disappear due to value set in localStorage
	 *
	 * see: the code comment within the `showPulseIfEligible('cc-loom-x-sell-discoverability-pulse-5')`
	 * check for more context on the special behavior
	 */
	return { isPulseActive: isPulseActive && fg('confluence_frontend_loom_discoverability_pulse') };
};
