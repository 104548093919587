import React, {
	type FocusEventHandler,
	type KeyboardEventHandler,
	type MouseEventHandler,
	type ReactNode,
	useCallback,
	useRef,
	useState,
	useEffect,
	useContext,
} from 'react';
import { mergeRefs } from 'use-callback-ref';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Heading from '@atlaskit/heading';
import AkImage from '@atlaskit/image';
import { LoomAttributionLogo } from '@atlaskit/logo';
import Popup, { type PopupProps, type TriggerProps as PopupTriggerProps } from '@atlaskit/popup';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';

import {
	ExperienceTrackerContext,
	LOOM_CROSS_SELL_EXPERIENCE,
} from '@confluence/experience-tracker';

import LoomCrossSellPopupCardImage from './images/LoomCrossSellPopupCardImage.png';

export const TOGGLE_DELAY_TIME_MS = 400;

const cardStyles = xcss({
	borderRadius: 'border.radius',
	overflow: 'auto',
	width: '352px',
});

const cardContentStyles = xcss({
	padding: 'space.300',
});

const imageContainerStyles = xcss({
	height: '201px',
});

export type EntryPointId =
	| 'loom-confluence-comment-editor-toolbar'
	| 'loom-confluence-create-menu'
	| 'loom-confluence-inline-comment'
	| 'loom-confluence-page-editor-toolbar'
	| 'loom-confluence-page-header';

export interface TriggerProps extends PopupTriggerProps {
	isSelected?: boolean;
	onBlur?: FocusEventHandler;
	onFocus?: FocusEventHandler;
	onKeyDown?: KeyboardEventHandler;
	onMouseEnter?: MouseEventHandler;
	onMouseLeave?: MouseEventHandler;
}

export type BaseLoomPopupCardProps = {
	actions?: ReactNode;
	description: string;
	entryPointId: EntryPointId;
	placement?: PopupProps['placement'];
	shouldRenderToParent?: boolean;
	title: string;
	trigger: (triggerProps: TriggerProps) => ReactNode;
	sourceAdType?: string;
};

export const BaseLoomPopupCard = ({
	actions,
	description,
	entryPointId,
	placement,
	shouldRenderToParent = true,
	title,
	trigger,
	sourceAdType,
}: BaseLoomPopupCardProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isOpen, setIsOpen] = useState(false);

	const hasStartedPreloadingImage = useRef<boolean>(false);
	const closePopupTimerRef = useRef<NodeJS.Timeout | null>(null);
	const openPopupTimerRef = useRef<NodeJS.Timeout | null>(null);

	const contentWrapperElementRef = useRef<HTMLElement | null>(null);
	const triggerElementRef = useRef<HTMLElement | null>(null);

	const isOpenOnFocusDisabled = useRef(false);

	const clearClosePopupTimer = useCallback(() => {
		if (closePopupTimerRef.current) {
			clearTimeout(closePopupTimerRef.current);
			closePopupTimerRef.current = null;
		}
	}, []);

	const clearOpenPopupTimer = useCallback(() => {
		if (openPopupTimerRef.current) {
			clearTimeout(openPopupTimerRef.current);
			openPopupTimerRef.current = null;
		}
	}, []);

	const experienceTracker = useContext(ExperienceTrackerContext);

	useEffect(() => {
		if (isOpen) {
			experienceTracker.start({
				name: LOOM_CROSS_SELL_EXPERIENCE,
				attributes: {
					sourceComponent: entryPointId,
					adType: sourceAdType,
				},
			});
		}
	}, [entryPointId, experienceTracker, isOpen, sourceAdType]);

	/**
	 * Temporarily disable the popup from re-opening (for a tick) on focus
	 * because in many cases, closing the popup returns focus back to the
	 * trigger, which would normally open the popup again.
	 */
	const safeClosePopup = useCallback(() => {
		setIsOpen(false);
		experienceTracker.abort({
			name: LOOM_CROSS_SELL_EXPERIENCE,
			reason: 'Loom pop up closed',
			attributes: {
				sourceComponent: entryPointId,
				adType: sourceAdType,
			},
		});
		isOpenOnFocusDisabled.current = true;
		setTimeout(() => {
			isOpenOnFocusDisabled.current = false;
		}, 0);
	}, [entryPointId, experienceTracker, sourceAdType]);

	const startClosePopupTimer = useCallback(() => {
		clearOpenPopupTimer();
		if (!closePopupTimerRef.current) {
			closePopupTimerRef.current = setTimeout(() => {
				safeClosePopup();
				closePopupTimerRef.current = null;
			}, TOGGLE_DELAY_TIME_MS);
		}
	}, [clearOpenPopupTimer, safeClosePopup]);

	/**
	 * Don't open the popup card immediately on hover or focus
	 *
	 * 1) Focus: We want KB users to be able to quickly navigate through areas with entrypoints that
	 * are alongside many other UI controls (e.g., Page Header, Page Editor Toolbar) without triggering
	 * the popup card and getting "stuck" (needing to hit "esc" to continue).
	 *
	 * 2) Hover: We don't want users to feel like the popup card gets in their way of clicking other
	 * things in the UI just because their mouse briefly brushed over the entrypoint button.
	 *
	 * This was heavily inspired by the hover cards used in the Page Tree (`useHoverPageCard`)
	 */
	const startOpenPopupTimer = useCallback(() => {
		clearClosePopupTimer();
		if (!hasStartedPreloadingImage.current) {
			hasStartedPreloadingImage.current = true;
			const img = new Image();
			img.src = LoomCrossSellPopupCardImage;
		}
		if (!openPopupTimerRef.current) {
			openPopupTimerRef.current = setTimeout(() => {
				createAnalyticsEvent({
					type: 'sendScreenEvent',
					data: {
						name: 'loomAdPopupCard',
						attributes: {
							adType: sourceAdType,
							entrypoint: entryPointId,
						},
					},
				}).fire();

				setIsOpen(true);
				openPopupTimerRef.current = null;
			}, TOGGLE_DELAY_TIME_MS);
		}
	}, [clearClosePopupTimer, createAnalyticsEvent, entryPointId, sourceAdType]);

	const handleTriggerBlur = useCallback(() => {
		clearOpenPopupTimer();
	}, [clearOpenPopupTimer]);

	const handleTriggerFocus = useCallback(() => {
		if (!isOpenOnFocusDisabled.current) {
			startOpenPopupTimer();
		}
	}, [startOpenPopupTimer]);

	const handleTriggerMouseEnter = useCallback(() => {
		startOpenPopupTimer();
	}, [startOpenPopupTimer]);

	const handleCardMouseEnter = useCallback(() => {
		clearClosePopupTimer();
	}, [clearClosePopupTimer]);

	const handleMouseLeave = useCallback(() => {
		/**
		 * Don't close the popup card when moving the mouse outside of it (or the trigger) if something
		 * inside it (or the trigger itself) is focused
		 */
		if (
			!contentWrapperElementRef.current?.contains(document.activeElement) &&
			document.activeElement !== triggerElementRef.current
		) {
			startClosePopupTimer();
		}
	}, [startClosePopupTimer]);

	const content = () => (
		<Box
			onMouseEnter={handleCardMouseEnter}
			onMouseLeave={handleMouseLeave}
			ref={contentWrapperElementRef}
			xcss={cardStyles}
		>
			<Box xcss={imageContainerStyles}>
				<AkImage alt="" src={LoomCrossSellPopupCardImage} />
			</Box>
			<Stack space="space.300" xcss={cardContentStyles}>
				<Stack space="space.100">
					<Heading as="h2" size="small">
						{title}
					</Heading>
					<Text as="p" color="color.text.subtle">
						{description}
					</Text>
				</Stack>
				<Inline alignBlock="center" spread="space-between">
					<LoomAttributionLogo appearance="brand" size="medium" />
					{actions}
				</Inline>
			</Stack>
		</Box>
	);

	return (
		<Popup
			autoFocus={false}
			content={content}
			isOpen={isOpen}
			/**
			 * Using `titleId` and adding the same value as an `id` to the Heading in the popup content
			 * does not announce properly in macOS VoiceOver
			 */
			label={title}
			onClose={safeClosePopup}
			placement={placement || 'auto'}
			role="dialog"
			shouldRenderToParent={shouldRenderToParent}
			trigger={(popupTriggerProps) =>
				trigger({
					...popupTriggerProps,
					isSelected: isOpen,
					onBlur: handleTriggerBlur,
					onFocus: handleTriggerFocus,
					onMouseEnter: handleTriggerMouseEnter,
					onMouseLeave: handleMouseLeave,
					ref: mergeRefs([popupTriggerProps.ref, triggerElementRef]),
				})
			}
		/>
	);
};
