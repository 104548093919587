import React, { useEffect, type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Anchor, Box, Text, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';

import {
	AdControlProvider,
	AdControlDropdownMenu,
	DismissalButton,
	ReasonsButton,
} from '@atlassian/ad-control-toolkit';

import { type EntryPointId } from '../LoomCrossSellPopupCard/BaseLoomPopupCard';

export const DATA_PRIVACY_LINK = 'https://www.atlassian.com/legal/privacy-policy';

export const loomAdControlI18n = defineMessages({
	dismissFlagTitle: {
		id: 'loom-utils.loom-ad-control.dismiss-flag-title',
		defaultMessage: 'Got it, thanks for letting us know',
		description:
			'Title of the flag that shows up when a user dismisses one of the loom videos cross-flow touchpoints.',
	},
	dismissFlagDescription: {
		id: 'loom-utils.loom-ad-control.dismiss-flag-description',
		defaultMessage: 'You won’t see this here as often.',
		description:
			'Description of the flag that shows up when a user dismisses one of the loom videos cross-flow touchpoints.',
	},
	reasonsModalTitle: {
		id: 'loom-utils.loom-ad-control.reasons-modal-title',
		defaultMessage: 'Why am I seeing this?',
		description:
			'Title of the reasons modal explaining why the loom cross-flow touchpoint is being shown',
	},
	reasonsModalExplanation: {
		id: 'loom-utils.loom-ad-control.reasons-modal-explanation',
		defaultMessage:
			'We think that <strong>Loom</strong> will improve your team’s productivity. The recommendation is based on:',
		description: 'Explanation as to why the loom cross-flow touchpoint is being shown',
	},
	reasonsModalBulletOne: {
		id: 'loom-utils.loom-ad-control.reasons-modal-bullet-one',
		defaultMessage: 'the size of your team',
		description: 'First bullet point of the reasons modal for loom cross-flow touchpoint ad',
	},
	reasonsModalBulletTwo: {
		id: 'loom-utils.loom-ad-control.reasons-modal-bullet-two',
		defaultMessage: 'your existing Atlassian products',
		description: 'Second bullet point of the reasons modal for loom cross-flow touchpoint ad',
	},
	privacyPolicy: {
		id: 'loom-utils.loom-ad-control.privacy-policy',
		defaultMessage: 'Learn more about your data privacy with Atlassian',
		description: "Link to Atlassian's privacy policy",
	},
});

const linkStyles = xcss({
	marginTop: 'space.150',
});

const moreIconStyles = xcss({
	alignSelf: 'center',
});

type LoomAdControlReasonModalBodyProps = {
	// entrypoint id of the entrypoint the popup is triggered from
	entryPointId: EntryPointId;
};

const LoomAdControlReasonModalBody: React.FC<LoomAdControlReasonModalBodyProps> = ({
	entryPointId,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'loomAdControlReasonsModal',
				attributes: {
					adType: 'crossFlow',
					entrypoint: entryPointId,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, entryPointId]);

	return (
		<>
			<Text>
				{formatMessage(loomAdControlI18n.reasonsModalExplanation, {
					strong: (chunks: ReactNode[]) => <Text weight="bold">{chunks}</Text>,
				})}
			</Text>
			<ul>
				<li>{formatMessage(loomAdControlI18n.reasonsModalBulletOne)}</li>
				<li>{formatMessage(loomAdControlI18n.reasonsModalBulletTwo)}</li>
			</ul>
			<Box xcss={linkStyles}>
				<Anchor href={DATA_PRIVACY_LINK} target="_blank">
					{formatMessage(loomAdControlI18n.privacyPolicy)}
				</Anchor>
			</Box>
		</>
	);
};

type AdControlProps = {
	onDismiss: () => Promise<void>;
	// entrypoint id of the entrypoint the popup is triggered from
	entryPointId: EntryPointId;
};

export const LoomAdControl: React.FC<AdControlProps> = ({ onDismiss, entryPointId }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	return (
		<AdControlProvider>
			<AdControlDropdownMenu
				itemId="loom-ad-controls"
				trigger={({ triggerRef, ...props }) => (
					<Box xcss={moreIconStyles}>
						<IconButton
							{...props}
							onClick={(e) => {
								createAnalyticsEvent({
									type: 'sendUIEvent',
									data: {
										action: 'clicked',
										actionSubject: 'button',
										actionSubjectId: 'toggleLoomAdControlDropdown',
										source: 'loomCrossFlowPopupCard',
										attributes: {
											adType: 'crossFlow',
											entrypoint: entryPointId,
										},
									},
								}).fire();

								props.onClick?.(e);
							}}
							appearance="subtle"
							spacing="compact"
							icon={ShowMoreHorizontalIcon}
							ref={triggerRef}
							label="LoomAdControl"
							testId="loom-utils.loom-ad-control.button"
						/>
					</Box>
				)}
			>
				{(props) => (
					<>
						<DismissalButton
							{...props}
							flagProps={{
								title: formatMessage(loomAdControlI18n.dismissFlagTitle),
								description: formatMessage(loomAdControlI18n.dismissFlagDescription),
							}}
							onClick={onDismiss}
						/>
						<ReasonsButton
							{...props}
							onClick={() => {
								createAnalyticsEvent({
									type: 'sendUIEvent',
									data: {
										action: 'clicked',
										actionSubject: 'button',
										actionSubjectId: 'toggleLoomAdControlReasonsModal',
										source: 'loomAdControlDropdown',
										attributes: {
											adType: 'crossFlow',
											entrypoint: entryPointId,
										},
									},
								}).fire();
							}}
							modalProps={{
								title: formatMessage(loomAdControlI18n.reasonsModalTitle),
								body: <LoomAdControlReasonModalBody entryPointId={entryPointId} />,
							}}
						/>
					</>
				)}
			</AdControlDropdownMenu>
		</AdControlProvider>
	);
};
