import { createStore, createHook } from 'react-sweet-state';
import { type LoomVideo } from '@loomhq/record-sdk';

type LoomInsertUrlStateType = {
	/**
	 * Loom recorder SDK return object to be passed to full page editor
	 */
	fullPageEditorLoomUrl: undefined | LoomVideo;
	/**
	 * Loom recorder SDK return object to be passed to page comment editor
	 */
	pageCommentEditorLoomUrl: undefined | LoomVideo;
};

type LoomInsertUrlActionType = {
	updateFullPageEditorLoomUrl: (video: LoomVideo | undefined) => ({ setState }) => void;
	updatePageCommentEditorLoomUrl: (video: LoomVideo | undefined) => ({ setState }) => void;
};

const Store = createStore<LoomInsertUrlStateType, LoomInsertUrlActionType>({
	initialState: {
		fullPageEditorLoomUrl: undefined,
		pageCommentEditorLoomUrl: undefined,
	},
	actions: {
		updateFullPageEditorLoomUrl:
			(video) =>
			({ setState }) => {
				setState({
					fullPageEditorLoomUrl: video,
				});
			},
		updatePageCommentEditorLoomUrl:
			(video) =>
			({ setState }) => {
				setState({
					pageCommentEditorLoomUrl: video,
				});
			},
	},
	name: 'loomUrlToInsert',
});

export const useLoomInsertUrlState = createHook(Store);
