import React, { useCallback, useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useIsReducedMotion } from '@atlaskit/motion';
import {
	Spotlight,
	SpotlightManager,
	SpotlightTarget,
	SpotlightTransition,
} from '@atlaskit/onboarding';
import { P300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useLoomEntryPointVariant } from '@confluence/loom-utils';

import spotlightImage from './assets/pageHeaderSpotlightImage.png';

const i18n = defineMessages({
	body: {
		id: 'loom-onboarding.page-header-button-spotlight.content',
		defaultMessage: 'Record a Loom video to share a personal walkthrough of your page.',
		description: 'Text for the page header Loom record button spotlight',
	},
	dismissButton: {
		id: 'loom-onboarding.page-header-button-spotlight.dismissButton',
		defaultMessage: 'Dismiss',
		description: 'Text on the button to click to indicate you are done viewing the spotlight',
	},
	tryItOutButton: {
		id: 'loom-onboarding.page-header-button-spotlight.tryItOutButton',
		defaultMessage: 'Try it out',
		description: 'Text on the button to click to try recording a Loom',
	},
	bodyGA: {
		id: 'loom-onboarding.page-header-button-spotlight.ga-content',
		defaultMessage: 'Record a Loom video to create a personal walkthrough of your page.',
		description: 'Text for the page header Loom record button GA spotlight',
	},
	buttonTextGA: {
		id: 'loom-onboarding.page-header-button-spotlight.ga-button-text',
		defaultMessage: 'Record',
		description: 'Text on the button to click and start recording a Loom',
	},
});

// Backup outline in case we disable the spotlight pulse for a11y reasons
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const SpotlightTargetContainer = styled.div({
	borderRadius: '6px',
	boxShadow: `0 0 0 2px ${token('color.border.discovery', P300)}`,
});

const SCREEN_NAME = 'pageHeaderLoomButtonBetaOnboardingSpotlight';
const GA_SCREEN_NAME = 'pageHeaderLoomButtonGAOnboardingSpotlight';

export type PageHeaderLoomButtonSpotlightProps = {
	/**
	 * spotlight trigger component.
	 */
	children?: React.ReactNode;
	/**
	 * spotlight dismiss button callback.
	 */
	onClose: () => void;
	/**
	 * spotlight "Try it out" button callback.
	 */
	onTryClick: () => void;
};

export const PageHeaderLoomButtonSpotlight = ({
	children,
	onClose,
	onTryClick,
}: PageHeaderLoomButtonSpotlightProps) => {
	const isReducedMotion = useIsReducedMotion();
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isClosed = useRef<boolean>(false);

	const { entryPointVariant } = useLoomEntryPointVariant();
	/**
	 * Since we are calling the entry point hook directly in here, the returned variant is "walking through" the
	 * various entry point values as the hook calls and returns the various permission status checks. Meaning the variant
	 * will first return CROSS_FLOW, then CROSS_JOIN, and finally CO_USE. To ensure we are correctly marking out GA analytics
	 * with the correct screen we can assume that if the variant isn't BETA, then we are in GA.
	 */
	const isGASpotlight = entryPointVariant !== 'BETA';
	const screenName = isGASpotlight ? GA_SCREEN_NAME : SCREEN_NAME;

	const close = useCallback(() => {
		if (!isClosed.current) {
			isClosed.current = true;
			onClose?.();
		}
	}, [onClose]);

	// Call `onClose()` when this component unmounts if it wasn't already closed in order to catch when the spotlight
	// disappears if users click outside of it. This is important because we need the parent to know when to call `stop()`
	// for the EP message so we don't block other onboarding from appearing for 5 min (the default expiration time)
	useEffect(() => {
		return () => {
			close();
		};
	}, [close]);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: screenName,
			},
		}).fire();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDismiss = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'dismiss',
				source: screenName,
			},
		}).fire();
		close();
	}, [close, createAnalyticsEvent, screenName]);

	const handleTryItOut = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: isGASpotlight ? 'record' : 'tryItOut',
				source: screenName,
			},
		}).fire();
		onTryClick();
		close();
	}, [close, onTryClick, createAnalyticsEvent, screenName, isGASpotlight]);

	const bodyMessage = isGASpotlight ? i18n.bodyGA : i18n.body;
	const buttonText = isGASpotlight ? i18n.buttonTextGA : i18n.tryItOutButton;

	return (
		<SpotlightManager>
			<SpotlightTarget name="page-header-loom-button-spotlight">
				<SpotlightTargetContainer>{children}</SpotlightTargetContainer>
			</SpotlightTarget>
			<SpotlightTransition>
				<Spotlight
					target="page-header-loom-button-spotlight"
					targetBgColor={token('color.text.inverse')}
					pulse={!isReducedMotion}
					targetRadius={6}
					dialogWidth={275}
					image={spotlightImage}
					actions={[
						{
							text: intl.formatMessage(buttonText),
							onClick: handleTryItOut,
						},
						{
							text: intl.formatMessage(i18n.dismissButton),
							onClick: handleDismiss,
							appearance: 'subtle',
						},
					]}
				>
					<FormattedMessage {...bodyMessage} />
				</Spotlight>
			</SpotlightTransition>
		</SpotlightManager>
	);
};
