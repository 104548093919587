import { useCallback, useContext } from 'react';
import { useCrossFlow, Journeys, Targets } from '@atlassiansox/cross-flow-support';

import {
	ExperienceTrackerContext,
	LOOM_CROSS_SELL_EXPERIENCE,
} from '@confluence/experience-tracker';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { type EntryPointId } from './LoomCrossSellPopupCard/BaseLoomPopupCard';

type CrossFlowRequestAccessOptions = {
	sourceComponent: EntryPointId;
};

export const useLoomCrossFlowRequestAccessCallback = ({
	sourceComponent,
}: CrossFlowRequestAccessOptions) => {
	const crossFlow = useCrossFlow();
	const experienceTracker = useContext(ExperienceTrackerContext);
	return useCallback(async () => {
		if (!crossFlow.isEnabled) {
			experienceTracker.fail({
				name: LOOM_CROSS_SELL_EXPERIENCE,
				error: new Error('Loom crossflow is not enabled'),
				attributes: {
					sourceComponent,
					adType: 'crossFlow',
				},
			});
			return;
		}

		experienceTracker.succeed({
			name: LOOM_CROSS_SELL_EXPERIENCE,
			attributes: {
				sourceComponent,
				adType: 'crossFlow',
			},
		});

		try {
			/**
			 * `success` in the response only indicates whether or not the user closed the drawer via
			 * the "back arrow" button. It does *not* indicate that the user succesfully completed cross-flow.
			 *
			 * Additionally, if the user *does* go through cross-flow, they'll be navigated to Loom.com and
			 * not return to this code path.
			 */
			await crossFlow.api.open({
				journey: Journeys.GET_STARTED,
				sourceComponent,
				sourceContext: 'confluence',
				targetProduct: Targets.LOOM,
			});
		} catch (error: any) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.CONTENT_TYPES,
			});
		}
	}, [crossFlow, experienceTracker, sourceComponent]);
};
