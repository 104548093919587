import React from 'react';

import { SpotlightPulse } from '@atlaskit/onboarding';

import { useDiscoverabilityPulse } from './useDiscoverabilityPulse';

type DiscoverabilityPulseProps = {
	children: JSX.Element;
	entryPointId: string;
};

export const DiscoverabilityPulse = ({ children, entryPointId }: DiscoverabilityPulseProps) => {
	const { isPulseActive } = useDiscoverabilityPulse(entryPointId);

	if (!isPulseActive) {
		return children;
	}

	return (
		<SpotlightPulse radius={3} testId="loom-cross-sell-discoverability-pulse">
			{children}
		</SpotlightPulse>
	);
};
