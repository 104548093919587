import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import Button, { LoadingButton } from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import {
	DiscoverabilityPulse,
	LoomCrossFlowPopupCard,
	LoomCrossJoinPopupCard,
	useLoomEntryPointErrorCallback,
	useLoomEntryPointVariant,
} from '@confluence/loom-utils';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';

import { PageHeaderLoomButton } from './PageHeaderLoomButton';
import { LoomVideoIcon } from './VideoIcon';

const ENTRYPOINT_ID = 'loom-confluence-page-header';

const i18n = defineMessages({
	crossJoinEntryPointLabel: {
		id: 'page-header-loom-button.crossJoinEntryPointLabel',
		defaultMessage: 'Join your team on Loom to record a video',
		description:
			'The a11y-friendly ARIA label for the Loom button in the page header menu when a user is eligible for cross-join',
	},
	crossSellPopupCardDescription: {
		id: 'page-header-loom-button.crossSellPopupCardDescription',
		defaultMessage:
			'Save time and engage your team with a video walkthrough, demo, update, and more.',
		description: 'Description inside a popup card advertising an opportunity to try Loom',
	},
	crossSellPopupCardTitle: {
		id: 'page-header-loom-button.crossSellPopupCardTitle',
		defaultMessage: 'Share your work with a video',
		description: 'Title of a popup card advertising an opportunity to try Loom',
	},
	entryPointLabel: {
		id: 'page-header-loom-button.entryPointLabel',
		defaultMessage: 'Record a Loom video',
		description:
			'The a11y-friendly ARIA label and tooltip for the button in the page header to record a Loom video.',
	},
});

type PageHeaderLoomEntryPointComponentProps = {
	contentId: string;
	spaceKey: string;
	contentType: string;
	flags: FlagsStateContainer;
	isCircle?: boolean;
	testId?: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonSpacer = styled.div({
	minWidth: '30px',
});

const PageHeaderLoomEntryPointComponent: FC<PageHeaderLoomEntryPointComponentProps> = ({
	contentId,
	spaceKey,
	contentType,
	flags,
	isCircle = false,
	testId,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { entryPointVariant, error, loading } = useLoomEntryPointVariant();
	const isEntryPointEnabled = ['BETA', 'CO_USE', 'CROSS_FLOW', 'CROSS_JOIN'].includes(
		entryPointVariant,
	);

	const intl = useIntl();

	const loomEntryPointErrorCallback = useLoomEntryPointErrorCallback({ flags });

	if (!isEntryPointEnabled) {
		return null;
	}

	if (entryPointVariant === 'BETA' || entryPointVariant === 'CO_USE') {
		return (
			<PageHeaderLoomButton
				contentId={contentId}
				contentType={contentType}
				dataVC="loom-button"
				label={intl.formatMessage(i18n.entryPointLabel)}
				spaceKey={spaceKey}
				ssrPlaceholderIdProp={ssrPlaceholderIdProp}
				isCircle={isCircle}
				testId={testId}
			/>
		);
	}

	if (error) {
		return (
			<Button
				appearance="subtle"
				aria-label={intl.formatMessage(i18n.entryPointLabel)}
				data-vc="loom-button"
				iconAfter={<LoomVideoIcon label="" color={token('color.text')} />}
				onClick={loomEntryPointErrorCallback}
			/>
		);
	}

	if (loading) {
		return (
			<LoadingButton
				testId="page-header-loom-entry-point-loading-button"
				appearance="subtle"
				aria-label={intl.formatMessage(i18n.entryPointLabel)}
				data-vc="loom-button"
				isLoading
			/>
		);
	}

	if (entryPointVariant === 'CROSS_FLOW') {
		return (
			<LoomCrossFlowPopupCard
				description={intl.formatMessage(i18n.crossSellPopupCardDescription)}
				entryPointId={ENTRYPOINT_ID}
				sourceActionSubjectId="createLoomHeaderItem"
				title={intl.formatMessage(i18n.crossSellPopupCardTitle)}
				trigger={(triggerProps) => (
					<DiscoverabilityPulse entryPointId={ENTRYPOINT_ID}>
						<ButtonSpacer>
							<Button
								{...triggerProps}
								appearance="subtle"
								aria-label={intl.formatMessage(i18n.entryPointLabel)}
								data-vc="loom-button"
								iconAfter={<LoomVideoIcon label="" color={token('color.text')} />}
								{...ssrPlaceholderIdProp}
							/>
						</ButtonSpacer>
					</DiscoverabilityPulse>
				)}
			/>
		);
	}

	if (entryPointVariant === 'CROSS_JOIN') {
		return (
			<LoomCrossJoinPopupCard
				description={intl.formatMessage(i18n.crossSellPopupCardDescription)}
				sourceActionSubjectId="createLoomHeaderItem"
				title={intl.formatMessage(i18n.crossSellPopupCardTitle)}
				entryPointId={ENTRYPOINT_ID}
				triggerLink={(href, { onClick, ...otherTriggerProps }) => (
					<DiscoverabilityPulse entryPointId={ENTRYPOINT_ID}>
						<ButtonSpacer>
							<Button
								{...otherTriggerProps}
								appearance="subtle"
								aria-label={intl.formatMessage(i18n.crossJoinEntryPointLabel)}
								data-vc="loom-button"
								href={href}
								iconAfter={<LoomVideoIcon label="" color={token('color.text')} />}
								rel="noreferrer"
								target="_blank"
								onClick={onClick}
								{...ssrPlaceholderIdProp}
							/>
						</ButtonSpacer>
					</DiscoverabilityPulse>
				)}
			/>
		);
	}

	// This condition currently isn't possible, but explicitly handling it here in case the
	// logic defining `isEntryPointEnabled` changes without updating this rendering behavior
	return null;
};

export const PageHeaderLoomEntryPoint = withErrorBoundary({
	attribution: Attribution.CONTENT_TYPES,
})(withFlags(PageHeaderLoomEntryPointComponent));
